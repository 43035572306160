import React from "react"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutAuthor from "../components/about-author"
import AboutOffer from "../components/about-offer"
import AboutClients from "../components/about-clients"
import { content } from "./pages.module.scss"
import { container, aboutContent } from "./author.module.scss"

export default function PageAuthor() {
    const currentLang = "pl"
    return (
        <PageTransition>
            <Layout lang={currentLang} bodyColor={'white'}>
                <Seo
                    lang={currentLang}
                    title="Autor"
                    keywords={[`powszuk`, `design`]}
                />
                <div className={`${content} ${container}`}>
                    <div className={aboutContent}>
                        <AboutAuthor lang={currentLang} />
                        <AboutOffer lang={currentLang} />
                        <AboutClients lang={currentLang} />
                    </div>
                </div>
            </Layout>
        </PageTransition>
    )
}
